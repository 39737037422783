<template>
  <Transition name="fade">
    <div v-if="isModalInfoVisible" tabindex="1" class="modal is-active">
      <div class="modal-background" @click="onClickClose"></div>
      <div class="animation-content">
        <div class="card">
          <div class="card__header">
            {{ modalInfoTitle }}
            <div class="card__header__icon-close" @click="onClickClose">
              <BIcon icon="close" />
            </div>
          </div>
          <div class="card__content">
            <Component :is="modalInfoContentComponent" class="card__content__component" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import { clickBtnPopupLogin, clickBtnPopupCrearCuenta, clickBtnPopupCerrar } from '@/utils/analytics.js';
import ScientificTipsInfo from '@/components/Elements/ScientificTipsInfo';
import TopBetsInfo from '@/components/Elements/TopBetsInfo';
import AmountInfo from '@/components/Elements/AmountInfo';
import RiskInfo from '@/components/Elements/RiskInfo';
import StrategyInfo from '@/components/Elements/StrategyInfo';
import TypeOfBets from '@/components/Elements/TypeOfBets';
import TableInfo from '@/components/Elements/TableInfo';
import { BIcon } from 'buefy/dist/esm/icon';

export default {
  name: 'ModalInfo',
  components: {
    ScientificTipsInfo,
    TopBetsInfo,
    AmountInfo,
    RiskInfo,
    StrategyInfo,
    TypeOfBets,
    TableInfo,
    BIcon,
  },
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    isOpened: {
      immediate: true,
      handler: function(newValue) {
        this.isActive = newValue;
      },
    },
    isActive(value) {
      if (!value) this.$emit('closeTooltip');
    },
    displayPremiumMessage() {
      this.isActive = this.displayPremiumMessage;
    },
  },
  mounted() {
    this.isActive = this.isOpened;
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    ...mapState(['modalInfoTitle', 'isModalInfoVisible', 'modalInfoContentComponent']),
  },
  methods: {
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations(['SET_PREMIUM_MESSAGE', 'setModalInfoVisible']),
    onClickLogin() {
      clickBtnPopupLogin(this.getPremiumAccount, this.getIsGolstats);
      this.SET_AUTH_COMPONENT('Login');
      this.SET_PREMIUM_MESSAGE(false);
    },
    onClickCreateAccount() {
      clickBtnPopupCrearCuenta(this.getPremiumAccount, this.getIsGolstats);
      this.SET_AUTH_COMPONENT('Register');
      this.SET_PREMIUM_MESSAGE(false);
    },
    onClickClose() {
      clickBtnPopupCerrar(this.getPremiumAccount, this.getIsGolstats);
      this.setModalInfoVisible({ isVisible: false });
    },
  },
};
</script>

<style scoped lang="scss">
.modal-background {
  background-color: rgba(10, 10, 10, 0.2);
}
.card {
  background-color: #272e3e;
  color: #ffffff;
  border-radius: 12px;
  box-shadow: 4px 25px 32px 0 rgba(0, 0, 0, 0.32);
  height: 100%;

  &__header {
    position: relative;
    font-family: 'Circular-Std', 'sans-serif';
    font-size: 1.4rem;
    padding: 12px 26px 10px;
    border-radius: 12px 12px 0 0;
    background-color: #1a2130;

    &__icon-close {
      padding: 2px 4px;
      position: absolute;
      top: 0;
      right: 0;
      color: #9aaacd;
      cursor: pointer;
    }
  }

  &__content {
    padding: 24px 8px;
    overflow-y: auto;
    height: 88%;

    &__component {
      height: 96%;
      overflow-y: auto;
      padding: 0 43px;

      &::-webkit-scrollbar {
        right: -10px;
        width: 6px; /* width of the entire scrollbar */
      }
      &::-webkit-scrollbar-track {
        background-color: #272e3e;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5a6887; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
      }

      /*&::-webkit-scrollbar {
        display: none;
      }*/
    }
  }
}
.animation-content {
  max-width: 682px;
  height: 100%;
  max-height: 600px;
  padding: 2rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media only screen and (max-width: 524px) {
  .card__header {
    font-size: 15px;
  }
  .card__content {
    padding: 14px 8px;
    font-size: 13px;

    &__component {
      padding: 0 22px;
    }
  }
}
</style>
