<template>
  <div class="container">
    <p class="modal-info__text-highlighted">
      Somos la empresa que más datos por partido de futbol obtiene en el mundo.
    </p>
    <p>
      Nuestros algoritmos analizan científicamente las probabilidades de cada evento y las comparan contra lo que cada
      apuesta paga,
      <span class="modal-info__text-highlighted">para encontrar para ti las apuestas que mejor te pagan </span>
      en comparación a su probabilidad.
    </p>
    <p class="modal-info__text-highlighted">
      GOLSTATS Y LA LIGA MX
    </p>
    <p>
      Anualmente le proporcionamos a la Liga MX
      <span style="display: block">más de:</span>
    </p>
    <p class="statistic">
      - 26 mil Millones de datos que recolectamos de sus partidos
    </p>
    <a
      href="https://az755631.vo.msecnd.net/golstats-bets/liga-mx-guard1anes-2021.pdf"
      target="_blank"
      class="btn-example"
      @click="onClickVerEjemploInformeEstadistico"
    >
      Ejemplo
    </a>
    <p class="statistic">
      - 80% de los números que publica en sus redes
    </p>
    <a
      href="https://az755631.vo.msecnd.net/golstats-bets/redes.pdf"
      target="_blank"
      class="btn-example"
      @click="onClickVerEjemploDeRedesSociales"
    >
      Ejemplo
    </a>
  </div>
</template>

<script>
import { clickVerEjemploInformeEstadistico, clickVerEjemploDeRedesSociales } from '@/utils/analytics';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  methods: {
    onClickVerEjemploInformeEstadistico() {
      clickVerEjemploInformeEstadistico(this.getPremiumAccount, this.getIsGolstats);
    },
    onClickVerEjemploDeRedesSociales() {
      clickVerEjemploDeRedesSociales(this.getPremiumAccount, this.getIsGolstats);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  text-align: left;
  font-family: Avenir-Regular, sans-serif;

  & > p {
    display: block;
    margin: 1.2rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & .statistic {
    margin-top: 2.2rem;
  }

  & .btn-example {
    font-family: Circular-Std-Bold, serif;
    width: fit-content;
    margin-left: 8px;
    padding: 12px 44px;
    background-color: #428ee6;
    color: white;
    font-size: 1.1rem;
    border-radius: 20px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.32);
  }
}
</style>
